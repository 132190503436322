html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
a{
 text-decoration : none;
}

html body {
  margin: 0;
  box-orient: vertical;
  box-direction: normal;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

html body * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html div#app {
  backface-visibility: hidden;
  font-family: "PingFang SC", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #F6F6F6;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

input ,textarea{  
	background:none;  
	outline:none;  
	border:1px solid #ccc;
  outline: none;
  -webkit-appearance: none; /*去除系统默认的样式*/
}
input:focus{   
	border:none;
}

/*van相关(需要复制)*/
.van-cell {
  padding: 0.36rem !important;
  background: #FDFDFD !important;
  border-bottom: 0.001rem solid #DDDDDD !important;
  align-items: center;
}

.van-collapse-item__content {
  background: #FDFDFD !important;
}

.van-collapse-item__content {
  padding-left: 0.8rem !important;
}

.van-toast {
  width: 2.173333rem !important;
  font-size: 0.4rem !important;
}

.van-cell__left-icon,
.van-cell__right-icon {

  font-size: 0.4rem !important;

}
.van-collapse-item--border::after {
  border: none;
}
.van-collapse-item__content {
  padding-left: 1.346667rem!important;
}
/* .van-cell::after {
  border: none;
} */


/*顶部消失隐藏样式（需要复制）*/
.transform {
  transform: translateY(-2rem);
}

.transform-n {
  transform: translateY(0);
}

.top-bc {
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.icon-orgion {
  color: #fff;
}

.icon-guanbi {
  color: #186CEA;;
}


.icon-change {
  color: #186CEA;
}

.btn-orgion {
  color: #fff;
  border: 0.02rem solid #ffffff;
}

.btn-change {
  border: 0.02rem solid #186CEA;
  color: #186CEA;
}

/*tab样式*/
.md-tab-bar-item {
  font-size: 0.37rem;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #666666;

}

.md-tab-bar {
  background-color: #fff;
  box-shadow: 0px 0.027rem 0px 0px rgba(75, 75, 75, 0.14);
}

.md-tab-bar-end {
  width: 0px !important;
}

.md-tab-bar-start {
  width: 0px !important;
}

.md-tab-bar-end:after,
.md-tab-bar-start:after {
  width: 0px !important;
  box-shadow: inherit;
}

.toTop {
  position: fixed;
  top: 1.6rem;
}

.toTop-m {
  position: fixed;
  top: 0;
}


.title-toptxt {
  font-size: .453333rem;
  font-weight: Bold;
  color: #333;
}

.title-txt {
  font-size: 0.305556rem !important;
  line-height: 1.5;
  color: #666666 !important;
  margin: 0.08rem 0 0.1rem !important;
}

.title-bottom {
  margin: 0.15rem auto 0 auto;
  width: 1.555556rem;
  height: 0.069444rem;
  border-radius: .186667rem;
  background-image: linear-gradient(
    to right,
    rgba(59, 135, 249, 1),
    rgba(235, 152, 38, 1)
  );
}


.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 0.15rem;
}
.picture-pagination > .swiper-pagination-bullet {
  width: 0.2rem;
  height: 0.2rem;
  background: none;
  opacity: 1;
  border: 1px solid #fff;
}
.picture-pagination > .swiper-pagination-bullet-active {
  opacity: 1;
  background: #ffffff;
}

.swiper-pagination-price {
  position: static !important;
  font-size: 0.34rem;
  color: #ffffff;
  font-weight: 400;
  margin-top: 0.73rem;
}
.swiper-pagination-current {
  font-size: 0.53rem;
  color: #ffffff;
}
.midPage {
  opacity: 0.44;
}
.swiper-pagination-total {
  opacity: 0.44;
}
.md-tab-picker .md-tabs-content {
  height: 10.666667rem;
}


@media screen and (min-width: 967px) { 
  /* pc端样式兼容-满意度问卷调查页-questionnaire.vue */
  .van-rate__icon {
    font-size: 21PX;
  }
  .van-button {
    height: auto;
  }
   .van-button--normal {
    font-size: 14PX;
    padding: 12PX 20PX;
    width: 150PX;
  }
   .md-popup.center {
    align-items: start;
  }
   .md-toast .md-popup .md-popup-box {
    width: 380PX;
    height: 48PX;
    overflow: hidden;
  }
   .md-toast-content {
    padding: 0;
    justify-content: center;
    font-size: 14PX;
  }
  .md-icon.icon-font.lg {
    font-size: 19px;
  }
}

ul,
li {
  list-style: none;
}

/* 悬浮样式 */
.sideTip {
  z-index: 999;
  position: fixed;
  text-align: center;
  height: 1.826667rem;
  background-color: rgba(255, 255, 255, 0.73);
  -webkit-box-shadow: 0 0.04rem 0.08rem rgb(0 0 0 / 50%);
          box-shadow: 0 0.04rem 0.08rem rgb(0 0 0 / 50%);
  width: 100%;
  bottom: 0;
}
.sideTip > a {
  display: inline-block;
  width: 4rem;
  height: 0.96rem;
  border: .013rem solid #186cea;
  border-radius: 0.48rem;
  font-size: 0.413333rem;
  transform: translateY(50%);
  -webkit-transform: translateY(50%);
  text-align: center;
}
.sheet-box {
  background: #ffffff;
  color: #186cea;
  margin-right: 0.8rem;
}
.tel-box {
  background: #186cea;
  color: #fff;
}
.sideTip > a > img {
  width: 0.48rem;
  height: 0.48rem;
  top: 50%;
  transform: translateY(20%);
  -webkit-transform: translateY(20%);
}
.fixTip {
  display: inline-block;
  line-height: .96rem;
}
.top[data-v-2ac2a3fa] {
  width: 100%;
  background-color: #f1f1f1;
  overflow: auto;
}
.top-wrap[data-v-2ac2a3fa] {
  position: fixed;
  z-index: 1002;
  padding: 0.33rem 0.472222rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  height: 1.6rem;
}
.top-wrap .logo[data-v-2ac2a3fa] {
  height: 0.888889rem;
  width: 1.861111rem;
}
.top-wrap .nav-right[data-v-2ac2a3fa] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.top-wrap .nav-right .icon-x[data-v-2ac2a3fa] {
  color: rgb(24 108 234);
}
.top-wrap .nav-right .sheet[data-v-2ac2a3fa] {
  display: block;
  width: 2.486111rem;
  height: 0.597222rem;
  text-decoration: none;
  text-align: center;
  line-height: 0.597222rem;
  background-color: #fff0;
  font-size: 0.333333rem;
  border-radius: 0.361111rem;
  margin-right: 1.013889rem;
}
.top-wrap .nav-right .iconfont[data-v-2ac2a3fa] {
  font-size: 0.4rem;
  margin: auto 0;
}
.foot[data-v-490fe535] {
  width: 100%;
  text-align: center;
  padding-bottom: 1.83rem;
  background-color: #414141;
}
.fade-enter-active[data-v-490fe535],
.fade-leave-active[data-v-490fe535] {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.fade-enter[data-v-490fe535],
.fade-leave-to[data-v-490fe535] {
  opacity: 0;
}
.public-info[data-v-490fe535] {
  width: 2.8rem;
  position: absolute;
  left: 0.8rem;
  bottom: 2rem;
  z-index: 1;
}
.public-info div[data-v-490fe535] {
  background-color: #f3f3f3;
  color: #777;
  padding: 0.13rem 0;
  position: relative;
  font-size: 0.27rem;
}
.public-info div .iconfont[data-v-490fe535] {
  font-size: 0.27rem;
  position: absolute;
  right: 0.13rem;
}
.public-info img[data-v-490fe535] {
  width: 100% !important;
  height: 100% !important;
}
.douyin-info[data-v-490fe535] {
  width: 2.8rem;
  position: absolute;
  right: 0.8rem;
  bottom: 2rem;
  z-index: 1;
}
.douyin-info div[data-v-490fe535] {
  background-color: #f3f3f3;
  color: #777;
  padding: 0.13rem 0;
  position: relative;
  font-size: 0.27rem;
}
.douyin-info div .iconfont[data-v-490fe535] {
  font-size: 0.27rem;
  position: absolute;
  right: 0.13rem;
}
.douyin-info img[data-v-490fe535] {
  width: 100% !important;
  height: 100% !important;
}
#up-wrap[data-v-490fe535] {
  background-color: #5d5d5d;
}
#up-wrap .imgList[data-v-490fe535] {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 0.013333rem solid #ffffff23;
  height: 1.866667rem;
  padding: 0 1.79rem;
}
#up-wrap .imgList img[data-v-490fe535] {
  margin: auto;
  width: 0.87rem;
  height: 0.87rem;
}
.onlyStyle[data-v-490fe535] {
  color: #ffffff !important;
  font-size: 0.373333rem !important;
  opacity: 1 !important;
  text-decoration: none !important;
}
#nav-wraps .foot-block[data-v-490fe535] {
  padding: 0.3rem 0.3rem 0.45rem 0.453333rem;
  text-align: left;
}
#nav-wraps .foot-block span[data-v-490fe535] {
  color: #ffffff;
  font-size: 0.373333rem;
  font-weight: 500;
}
#nav-wraps .foot-block ul[data-v-490fe535] {
  font-size: 0.346667rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
}
#nav-wraps .foot-block ul li[data-v-490fe535] {
  width: 33.33333%;
  margin-top: 0.546667rem;
}
#nav-wraps .foot-block ul li a[data-v-490fe535] {
  color: #ffffff;
  opacity: 0.8;
  text-decoration: none;
}
#nav-wraps #fourBlock li[data-v-490fe535] {
  width: 25% !important;
}
#bottom-wrap[data-v-490fe535] {
  background-color: #414141;
  font-size: 0.24rem;
  color: #c5c5c5;
}
#bottom-wrap a[data-v-490fe535] {
  font-size: 0.24rem;
  color: #c5c5c5;
  text-decoration: none;
}
#bottom-wrap .tel[data-v-490fe535] {
  padding: 0.19rem 0 0.44rem 0;
  border-bottom: 0.013333rem solid #333232;
}
#bottom-wrap .banquan[data-v-490fe535] {
  padding: 0.27rem 0 0.2rem 0;
}
#bottom-wrap .other[data-v-490fe535] {
  padding-bottom: 0.4rem;
  position: relative;
}
#bottom-wrap .other img[data-v-490fe535] {
  position: absolute;
  left: 2.4rem;
  width: 0.26rem;
  height: auto;
}
.main[data-v-3d9436f5] {
  z-index: 999999;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding-top: 1.6rem;
  background: #fdfdfd;
}
.title[data-v-3d9436f5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.title img[data-v-3d9436f5] {
  height: 0.5rem;
  width: 0.5rem;
}
.title span[data-v-3d9436f5] {
  margin-left: 0.44rem;
  font-size: 0.413333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0.706667rem;
  color: #333333;
  opacity: 1;
}
.content p[data-v-3d9436f5] {
  text-align: left;
  font-size: 0.373333rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  opacity: 1;
  line-height: 1.133333rem;
}
.activeClass[data-v-3d9436f5] {
  color: #186cea !important;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f6f6f6;
  width: 100%;
  height: 100%;
}
.md-dialog-content {
  text-align: center;
}
.dialog-banner img {
  width: 5.733333rem !important;
  height: 5.733333rem !important;
  margin-top: 0.88rem !important;
}
.md-dialog-body {
  text-align: center;
  padding: 0.6rem 0;
}
.md-icon.icon-font.md {
  font-size: 0.5rem;
  font-weight: 500;
}
[data-v-74a1b3a5] .md-dialog-body {
  text-align: center !important;
}
.main[data-v-74a1b3a5] {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #f6f6f6;
  -webkit-overflow-scrolling: touch;
}
.main section[data-v-74a1b3a5] {
  padding-top: 0.733333rem;
  font-size: 0;
}
h1[data-v-74a1b3a5],
p[data-v-74a1b3a5] {
  font-family: '\5FAE\8F6F\96C5\9ED1';
}
#banner-wrap[data-v-74a1b3a5] {
  padding-top: 0;
}
#banner-wrap .swiper-slide[data-v-74a1b3a5] {
  width: 100%;
  height: 8.826667rem;
}
#banner-wrap img[data-v-74a1b3a5] {
  width: 100%;
  height: 100%;
}
#banner-wrap #first-screen[data-v-74a1b3a5] {
  background: url("/static/homepage-banner-1.png") 100% 100% no-repeat;
  -webkit-background-size: 100% 100%;
}
#banner-wrap #first-screen p[data-v-74a1b3a5] {
  margin-top: 6.586667rem;
  font-size: 0.346667rem;
  color: #ffffff;
}
#banner-wrap #second-screen[data-v-74a1b3a5] {
  background: url("/static/homepage-banner-2.png") 100% 100% no-repeat;
  -webkit-background-size: 100% 100%;
}
#banner-wrap #second-screen p[data-v-74a1b3a5] {
  width: 7.373333rem;
  height: 0.92rem;
  margin: 6.56rem auto 0 auto;
  font-size: 0.346667rem;
  line-height: 0.48rem;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 0 0.04rem 0.08rem rgba(0, 0, 0, 0.16);
  opacity: 1;
}
#banner-wrap .swiper-pagination[data-v-74a1b3a5] {
  bottom: 0.5rem;
}
#frame-wrap[data-v-74a1b3a5] {
  background-color: #ffffff;
  padding-bottom: 0.026667rem;
}
#video-wrap[data-v-74a1b3a5] {
  background-color: #fff;
}
#video-wrap img[data-v-74a1b3a5] {
  margin-top: 0.426667rem;
  width: 100%;
  height: auto;
  -o-object-fit: fill;
     object-fit: fill;
}
#solution-wrap[data-v-74a1b3a5] {
  margin-bottom: 1.066667rem;
}
#core-wrap[data-v-74a1b3a5] {
  background-color: #f6f6f6;
}
#core-wrap .core-content[data-v-74a1b3a5] {
  padding: 0.733333rem 0.4rem 0.333333rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#core-wrap .core-content .core-box[data-v-74a1b3a5] {
  background-color: #ffffff;
  width: 4.4rem;
  margin-bottom: 0.4rem;
  padding: 0.306667rem 0.333333rem 0.226667rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-shadow: 0 0 0.08rem rgba(0, 0, 0, 0.16);
          box-shadow: 0 0 0.08rem rgba(0, 0, 0, 0.16);
  border-radius: 0.186667rem;
}
#core-wrap .core-content .core-box img[data-v-74a1b3a5] {
  width: 0.933333rem;
  height: 0.933333rem;
  margin: 0 auto;
}
#core-wrap .core-content .core-box h2[data-v-74a1b3a5] {
  font-size: 0.333333rem;
  margin: 0.22rem 0 0.2rem;
  font-weight: bold;
  color: #333333;
}
#core-wrap .core-content .core-box h3[data-v-74a1b3a5] {
  font-size: 0.24rem;
  line-height: 1.3;
  color: #999999;
}
#custom-wrap[data-v-74a1b3a5] {
  background: #fff;
  opacity: 1;
}
#custom-wrap .swiper-wrapper[data-v-74a1b3a5] {
  width: 9.333333rem;
  height: 10.28rem;
}
#custom-wrap .swiper-slide[data-v-74a1b3a5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#custom-wrap .swiper-slide img[data-v-74a1b3a5] {
  width: 8.933333rem;
  height: 5.013333rem;
  margin: 0.2rem auto 0 auto;
}
#custom-wrap .swiper-slide .custom-comment[data-v-74a1b3a5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0.44rem;
}
#custom-wrap .swiper-slide .custom-comment span[data-v-74a1b3a5] {
  text-align: left;
  font-size: 0.426667rem;
  font-weight: 500;
  line-height: 0.4rem;
  color: #333333;
  opacity: 1;
}
#custom-wrap .swiper-slide .custom-comment .custom-txt[data-v-74a1b3a5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 0.786667rem;
  position: relative;
}
#custom-wrap .swiper-slide .custom-comment .custom-txt .comma[data-v-74a1b3a5] {
  position: absolute;
  top: -0.24rem;
  font-size: 1.066667rem;
  font-family: Arial;
  color: #333333;
  opacity: 0.27;
}
#custom-wrap .swiper-slide .custom-comment .custom-txt .comma[data-v-74a1b3a5]:last-child {
  top: 2.333333rem;
  right: 0;
}
#custom-wrap .swiper-slide .custom-comment .custom-txt p[data-v-74a1b3a5] {
  width: 8.12rem;
  height: 2.733333rem;
  margin: 0 0.493333rem 0.6rem 0.72rem;
  text-align: left;
  font-size: 0.373333rem;
  font-weight: 400;
  line-height: 0.573333rem;
  color: #666666;
  opacity: 1;
}
#custom-wrap .swiper-pagination[data-v-74a1b3a5] {
  position: absolute;
  bottom: 5.5rem;
}
#install-wrap .bottom-tips[data-v-74a1b3a5] {
  font-size: 0.32rem;
  font-weight: 400;
  line-height: 0.546667rem;
  color: #999999;
  opacity: 1;
  margin-top: 0.653333rem;
  padding-bottom: 0.573333rem;
}
#install-wrap .install-items[data-v-74a1b3a5] {
  margin-top: 0.7rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#install-wrap .install-items .item[data-v-74a1b3a5] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#install-wrap .install-items .item .item-imgs-wrap img[data-v-74a1b3a5] {
  width: 2.24rem;
  height: 2.24rem;
}
#install-wrap .install-items .item .txt-title[data-v-74a1b3a5] {
  font-size: 0.266667rem;
  margin-top: 0.266667rem;
  color: #333333;
}
.button-wraps[data-v-74a1b3a5] {
  display: inline-block;
  width: 3.013333rem;
  height: 0.84rem;
  line-height: 0.84rem;
  font-size: 0.386667rem;
  margin: 0.133333rem auto 0.533333rem auto;
  color: #186CEA;
  border: 0.013333rem solid #186CEA;
  opacity: 1;
  border-radius: 0.426667rem;
}
.title-toptxt[data-v-74a1b3a5] {
  font-size: 0.586667rem;
  font-weight: bold;
  line-height: 1rem;
  color: #333333;
  opacity: 1;
}
.title-txt[data-v-74a1b3a5] {
  margin-top: -0.133333rem !important;
  font-size: 0.32rem !important;
  font-weight: 400;
  line-height: 0.546667rem;
  color: #999999 !important;
  opacity: 1;
}
.frame-left-pic[data-v-74a1b3a5] {
  width: 0.8rem;
  height: 0.8rem;
  margin-left: 0.24rem;
}
.frame-right-pic[data-v-74a1b3a5] {
  position: absolute;
  right: 0.52rem;
  width: 0.453333rem;
  height: 0.453333rem;
}
.frame-ul-style[data-v-74a1b3a5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin: 0.266667rem 0.466667rem 0.266667rem 0.466667rem;
}
.frame-li-style[data-v-74a1b3a5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-top: 0.266667rem;
  width: 9.066667rem;
  height: 1.44rem;
  background: -webkit-gradient(linear, left top, left bottom, from(#F4F7F9), to(#FBFCFD));
  background: linear-gradient(180deg, #F4F7F9 0%, #FBFCFD 100%);
  -webkit-box-shadow: 0 0 0.08rem rgba(0, 0, 0, 0.16);
          box-shadow: 0 0 0.08rem rgba(0, 0, 0, 0.16);
  opacity: 1;
}
.frame-li-text[data-v-74a1b3a5] {
  margin-left: 0.2rem;
  font-size: 0.426667rem;
  font-weight: bold;
  line-height: 0.746667rem;
  color: #666666;
  opacity: 1;
}
.frame-table[data-v-74a1b3a5] {
  width: 9.066667rem;
  padding-bottom: 0.213333rem;
  margin-top: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#EEF1F6), to(#FEFEFD));
  background: linear-gradient(180deg, #EEF1F6 0%, #FEFEFD 100%);
  -webkit-box-shadow: 0 0 0.08rem rgba(0, 0, 0, 0.16);
          box-shadow: 0 0 0.08rem rgba(0, 0, 0, 0.16);
  opacity: 1;
}
.frame-table ul[data-v-74a1b3a5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.frame-table ul li[data-v-74a1b3a5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.4rem 0.453333rem;
  margin-top: -0.4rem;
  width: 9.066667rem;
}
.frame-table ul li img[data-v-74a1b3a5] {
  -ms-flex-item-align: start;
      align-self: flex-start;
  width: 0.666667rem;
  height: 0.666667rem;
  margin-right: 0.253333rem;
}
.frame-table li[data-v-74a1b3a5]:first-child {
  margin-top: 0.106667rem;
}
.frame-table-right[data-v-74a1b3a5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  font-size: 0.186667rem;
  width: 7.893333rem;
}
.frame-table-right p[data-v-74a1b3a5] {
  height: 0.4rem;
  font-size: 0.4rem;
  font-weight: 500;
  line-height: 0.746667rem;
  color: #333333;
  opacity: 1;
}
.frame-table-right ul[data-v-74a1b3a5] {
  margin: 0;
  padding: 0;
  width: 6.786667rem;
  font-size: 0.346667rem;
  font-weight: 400;
  line-height: 0.546667rem;
  color: #666666;
  opacity: 1;
}
.frame-little-text[data-v-74a1b3a5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 8rem;
  margin-top: 0.266667rem;
}
.frame-little-text span[data-v-74a1b3a5] {
  margin-right: 0.32rem;
  margin-top: 0.066667rem;
  font-size: 0.346667rem;
  font-weight: 400;
  line-height: 0.546667rem;
  color: #666666;
  opacity: 1;
}
.frame-list-actived[data-v-74a1b3a5] {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.fifth-only-style[data-v-74a1b3a5] {
  width: 6.666667rem;
}
.fifth-only-style span[data-v-74a1b3a5] {
  margin-right: 0;
  text-align: left;
}
.frame-second-list[data-v-74a1b3a5] {
  width: 100%;
  height: 6.626667rem;
  padding-right: 1.173333rem;
  padding-left: 1.173333rem;
}
.frame-second-list img[data-v-74a1b3a5] {
  width: 1.506667rem;
  height: 1.506667rem;
}
.frame-second-list .first-row[data-v-74a1b3a5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding-top: 0.893333rem;
  margin-bottom: 0.666667rem;
}
.frame-second-list .second-row[data-v-74a1b3a5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin-left: 1.266667rem;
}
.frame-second-item[data-v-74a1b3a5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 1.133333rem;
}
.frame-second-item span[data-v-74a1b3a5] {
  white-space: nowrap;
  text-align: center;
  font-size: 0.373333rem;
  font-weight: 500;
  line-height: 0.746667rem;
  color: #333333;
  opacity: 1;
}
.frame-firstitem-only[data-v-74a1b3a5] {
  margin-right: 0 !important;
}
.frame-seconditem-only[data-v-74a1b3a5] {
  margin-right: 1.066667rem;
}
.look-movie[data-v-74a1b3a5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0.4rem auto 0.4rem auto;
  width: 3.053333rem;
  height: 0.84rem;
  border: 0.013333rem solid #186CEA;
  opacity: 1;
  border-radius: 0.426667rem;
}
.look-movie div img[data-v-74a1b3a5] {
  width: 0.56rem;
  height: 0.56rem;
  margin-left: 0.36rem;
}
.look-movie span[data-v-74a1b3a5] {
  margin-top: 0.066667rem;
  margin-left: 0.2rem;
  font-size: 0.386667rem;
  font-weight: 400;
  line-height: 0.306667rem;
  color: #186CEA;
  opacity: 1;
}
.solution-box[data-v-74a1b3a5] {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 0.133333rem;
  margin: 0 auto;
  margin-top: 0.52rem;
  width: 9.466667rem;
  height: 16.373333rem;
}
.solution-box .solution-first-box[data-v-74a1b3a5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  background-image: url("/static/homepage-solution-dsyw-bg.png");
  background-position: center;
  background-size: cover;
}
.solution-box .solution-first-box h1[data-v-74a1b3a5] {
  margin-top: 1.133333rem;
  font-size: 0.48rem;
  font-weight: 800;
  line-height: 0.293333rem;
  color: #FFFFFF;
  opacity: 1;
}
.solution-box .solution-first-box .p1[data-v-74a1b3a5] {
  margin-top: 0.373333rem;
  font-size: 0.32rem;
  font-weight: 400;
  line-height: 0.386667rem;
  color: #FFFFFF;
  opacity: 1;
}
.solution-box .solution-first-box .p-only[data-v-74a1b3a5] {
  margin-top: 0.04rem;
}
.solution-box .solution-first-box .solution-more-btn[data-v-74a1b3a5] {
  margin-top: 0.573333rem;
  width: 2.4rem;
  height: 0.68rem;
  border: 0.013333rem solid #FFFFFF;
  opacity: 1;
  border-radius: 0.346667rem;
  padding-top: 0.266667rem;
}
.solution-box .solution-first-box .solution-more-btn span[data-v-74a1b3a5] {
  font-size: 0.32rem;
  font-weight: 400;
  line-height: 0.2rem;
  color: #FFFFFF;
}
.solution-item-box[data-v-74a1b3a5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background-image: url("/static/homepage-solution-other-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
.solution-item-box h1[data-v-74a1b3a5] {
  margin: 0.8rem 0 0 0.52rem;
  font-size: 0.453333rem;
  font-weight: bold;
  line-height: 0.293333rem;
  color: #333333;
  opacity: 1;
}
.solution-item-box p[data-v-74a1b3a5] {
  width: 3.613333rem;
  height: 0.733333rem;
  margin: 0.453333rem 0 0 0.52rem;
  text-align: start;
  font-size: 0.32rem;
  font-weight: 400;
  line-height: 0.386667rem;
  color: #666666;
  opacity: 1;
}
.solution-item-box a[data-v-74a1b3a5] {
  display: block;
  margin-top: 0.8rem;
  margin-left: 0.586667rem;
  font-size: 0.293333rem;
  font-weight: 400;
  line-height: 0.2rem;
  color: #186CEA;
  opacity: 1;
}
.solution-item-box img[data-v-74a1b3a5] {
  width: 0.866667rem;
  height: 0.866667rem;
  margin: 0 0.226667rem 0.2rem 3.506667rem;
}
#shine-wrap[data-v-74a1b3a5] {
  margin-top: 2.133333rem;
  background: url("/static/yunWMS-highlights-bg@2x.png") no-repeat 100% 100%;
  color: #fff;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-bottom: 0.1rem;
}
#shine-wrap .tab1 img[data-v-74a1b3a5] {
  border: 0.013333rem dashed #fefefe;
}
#shine-wrap .bc-white[data-v-74a1b3a5] {
  width: 9.466667rem;
  height: 8.533333rem;
  background: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#shine-wrap .bc-white .white-title[data-v-74a1b3a5] {
  font-size: 0.48rem;
  font-weight: bold;
  color: #186cea;
  opacity: 1;
  margin-top: 0.866667rem;
  margin-bottom: 0.466667rem;
}
#shine-wrap .bc-white div[data-v-74a1b3a5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#shine-wrap .bc-white div img[data-v-74a1b3a5] {
  margin-left: 1.12rem;
  margin-right: 0.48rem;
  height: 0.946667rem;
  width: 0.946667rem;
}
#shine-wrap .bc-white div p[data-v-74a1b3a5] {
  font-size: 0.4rem;
  font-weight: 400;
  line-height: 1.466667rem;
  color: #333333;
  opacity: 1;
}
#shine-wrap .bc-white .swipers-line[data-v-74a1b3a5] {
  border: none;
  border-top: 0.013333rem dashed #DDDDDD;
  width: 7.706667rem;
  margin: 0 auto;
}
#shine-wrap .bc-white .white-txt[data-v-74a1b3a5] {
  margin: 0.173333rem auto 0.6rem;
  width: 7rem;
  font-size: 0.266667rem;
  color: #999999;
}
#shine-wrap .bc-white .white-content[data-v-74a1b3a5] {
  width: 8.266667rem;
  background: rgba(0, 108, 255, 0.02);
  padding: 0.25rem 0 0.25rem 0.55rem;
  border: 0.02rem solid rgba(112, 112, 112, 0.2);
  border-radius: 0.133333rem;
  color: #999;
  text-align: left;
}
#shine-wrap .bc-white .white-content li[data-v-74a1b3a5] {
  font-size: 0.27rem;
  list-style-type: disc;
  line-height: 0.38rem;
}
#shine-wrap .bc-white .bc-content ul[data-v-74a1b3a5] {
  position: absolute;
  right: 0.26rem;
  top: 1rem;
}
#shine-wrap .bc-white .bc-content ul li[data-v-74a1b3a5] {
  font-size: 0.32rem;
  width: 3.8rem;
  color: #555;
  margin-bottom: 0.3rem;
  list-style-type: none;
  text-align: left;
  font-weight: bold;
}
#shine-wrap .bc-white .bc-content ul li p[data-v-74a1b3a5] {
  font-size: 0.24rem;
  margin-left: 0.24rem;
  margin-top: 0.1rem;
  line-height: 0.29rem;
  color: #999;
  font-weight: 400;
}
#shine-wrap .bc-white .bc-content ul li[data-v-74a1b3a5]:before {
  content: "";
  display: inline-block;
  width: 0.08rem;
  height: 0.08rem;
  background-color: #555555;
  border-radius: 50%;
  margin-right: 0.1rem;
}
#shine-wrap .shine-nav[data-v-74a1b3a5] {
  margin-top: 0.48rem;
}
#shine-wrap .vswiper[data-v-74a1b3a5] {
  margin-top: 0.34rem;
}
#shine-wrap[data-v-74a1b3a5] .vux-tab .vux-tab-item.vux-tab-selected {
  font-weight: 500;
  opacity: 1;
}
#shine-wrap[data-v-74a1b3a5] .vux-tab-container {
  overflow: unset;
}
#shine-wrap[data-v-74a1b3a5] .vux-tab {
  background-color: unset;
  padding-bottom: 0.35rem;
}
#shine-wrap[data-v-74a1b3a5] .vux-tab .vux-tab-item {
  font-size: 0.426667rem;
  font-weight: 500;
  background: unset;
  color: #FFFFFF !important;
  opacity: 0.47;
  white-space: nowrap;
}
#shine-wrap[data-v-74a1b3a5] .scrollable .vux-tab-item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 31%;
          flex: 0 0 31%;
}
#shine-wrap[data-v-74a1b3a5] .scrollable .vux-tab-ink-bar {
  bottom: 0.01rem;
}
#shine-wrap[data-v-74a1b3a5] .vux-tab-bar-inner {
  border-left: 0.17rem solid transparent;
  border-right: 0.17rem solid transparent;
  border-bottom: 0.17rem solid #fff;
  background-color: transparent;
}
.custom-swiper-box[data-v-74a1b3a5] {
  margin: 0.493333rem auto 0.52rem auto;
  width: 9.333333rem;
  background: rgba(255, 255, 255, 0.39);
  -webkit-box-shadow: 0 0 0.08rem rgba(0, 0, 0, 0.16);
          box-shadow: 0 0 0.08rem rgba(0, 0, 0, 0.16);
  opacity: 1;
}
.txt-title-only[data-v-74a1b3a5] {
  margin-top: 0.186667rem;
  font-size: 0.4rem;
  font-weight: 400;
  line-height: 0.68rem;
  color: #333333;
  opacity: 1;
}
.tab-third-only[data-v-74a1b3a5] {
  margin-left: 0.6rem;
}/**
* actionsheet
*/

/**
* en: primary type text color of menu item
* zh-CN: 菜单项primary类型的文本颜色
*/

/**
* en: warn type text color of menu item
* zh-CN: 菜单项warn类型的文本颜色
*/

/**
* en: default type text color of menu item
* zh-CN: 菜单项default类型的文本颜色
*/

/**
* en: disabled type text color of menu item
* zh-CN: 菜单项disabled类型的文本颜色
*/

/**
* datetime
*/

/**
* tabbar
*/

/**
* tab
*/

/**
* dialog
*/

/**
* en: title and content's padding-left and padding-right
* zh-CN: 标题及内容区域的 padding-left 和 padding-right
*/

/**
* x-number
*/

/**
* checkbox
*/

/**
* check-icon
*/

/**
* Cell
*/

/**
* Mask
*/

/**
* Range
*/

/**
* Tabbar
*/

/**
* Header
*/

/**
* Timeline
*/

/**
* Switch
*/

/**
* Button
*/

/**
* en: border radius
* zh-CN: 圆角边框
*/

/**
* en: font color
* zh-CN: 字体颜色
*/

/**
* en: margin-top value between previous button, not works when there is only one button
* zh-CN: 与相邻按钮的 margin-top 间隙，只有一个按钮时不生效
*/

/**
* en: button height
* zh-CN: 按钮高度
*/

/**
* en: the font color in disabled
* zh-CN: disabled状态下的字体颜色
*/

/**
* en: the font color in disabled
* zh-CN: disabled状态下的字体颜色
*/

/**
* en: font size
* zh-CN: 字体大小
*/

/**
* en: the font size of the mini type
* zh-CN: mini类型的字体大小
*/

/**
* en: the line height of the mini type
* zh-CN: mini类型的行高
*/

/**
* en: the background color of the warn type
* zh-CN: warn类型的背景颜色
*/

/**
* en: the background color of the warn type in active
* zh-CN: active状态下，warn类型的背景颜色
*/

/**
* en: the background color of the warn type in disabled
* zh-CN: disabled状态下，warn类型的背景颜色
*/

/**
* en: the background color of the default type
* zh-CN: default类型的背景颜色
*/

/**
* en: the font color of the default type
* zh-CN: default类型的字体颜色
*/

/**
* en: the background color of the default type in active
* zh-CN: active状态下，default类型的背景颜色
*/

/**
* en: the font color of the default type in disabled
* zh-CN: disabled状态下，default类型的字体颜色
*/

/**
* en: the background color of the default type in disabled
* zh-CN: disabled状态下，default类型的背景颜色
*/

/**
* en: the font color of the default type in active
* zh-CN: active状态下，default类型的字体颜色
*/

/**
* en: the background color of the primary type
* zh-CN: primary类型的背景颜色
*/

/**
* en: the background color of the primary type in active
* zh-CN: active状态下，primary类型的背景颜色
*/

/**
* en: the background color of the primary type in disabled
* zh-CN: disabled状态下，primary类型的背景颜色
*/

/**
* en: the font color of the plain primary type
* zh-CN: plain的primary类型的字体颜色
*/

/**
* en: the border color of the plain primary type
* zh-CN: plain的primary类型的边框颜色
*/

/**
* en: the font color of the plain primary type in active
* zh-CN: active状态下，plain的primary类型的字体颜色
*/

/**
* en: the border color of the plain primary type in active
* zh-CN: active状态下，plain的primary类型的边框颜色
*/

/**
* en: the font color of the plain default type
* zh-CN: plain的default类型的字体颜色
*/

/**
* en: the border color of the plain default type
* zh-CN: plain的default类型的边框颜色
*/

/**
* en: the font color of the plain default type in active
* zh-CN: active状态下，plain的default类型的字体颜色
*/

/**
* en: the border color of the plain default type in active
* zh-CN: active状态下，plain的default类型的边框颜色
*/

/**
* en: the font color of the plain warn type
* zh-CN: plain的warn类型的字体颜色
*/

/**
* en: the border color of the plain warn type
* zh-CN: plain的warn类型的边框颜色
*/

/**
* en: the font color of the plain warn type in active
* zh-CN: active状态下，plain的warn类型的字体颜色
*/

/**
* en: the border color of the plain warn type in active
* zh-CN: active状态下，plain的warn类型的边框颜色
*/

/**
* swipeout
*/

/**
* Cell
*/

/**
* Badge
*/

/**
* en: badge background color
* zh-CN: badge的背景颜色
*/

/**
* Popover
*/

/**
* Button tab
*/

/**
* en: not used
* zh-CN: 未被使用
*/

/**
* en: border radius color
* zh-CN: 圆角边框的半径
*/

/**
* en: border color
* zh-CN: 边框的颜色
*/

/**
* en: not used
* zh-CN: 默认状态下圆角边框的颜色
*/

/**
* en: not used
* zh-CN: 未被使用
*/

/**
* en: default background color
* zh-CN: 默认状态下的背景颜色
*/

/**
* en: selected background color
* zh-CN: 选中状态下的背景颜色
*/

/**
* en: not used
* zh-CN: 未被使用
*/

/* alias */

/**
* en: not used
* zh-CN: 未被使用
*/

/**
* en: default text color
* zh-CN: 默认状态下的文本颜色
*/

/**
* en: height
* zh-CN: 元素高度
*/

/**
* en: line height
* zh-CN: 元素行高
*/

/**
* Swiper
*/

/**
* checklist
*/

/**
* popup-picker
*/

/**
* popup
*/

/**
* popup-header
*/

/**
* form-preview
*/

/**
* sticky
*/

/**
* group
*/

/**
* en: margin-top of title
* zh-CN: 标题的margin-top
*/

/**
* en: margin-bottom of title
* zh-CN: 标题的margin-bottom
*/

/**
* en: margin-top of footer title
* zh-CN: 底部标题的margin-top
*/

/**
* en: margin-bottom of footer title
* zh-CN: 底部标题的margin-bottom
*/

/**
* toast
*/

/**
* en: text size of content
* zh-CN: 内容文本大小
*/

/**
* en: default top
* zh-CN: 默认状态下距离顶部的高度
*/

/**
* en: position top
* zh-CN: 顶部显示的高度
*/

/**
* en: position bottom
* zh-CN: 底部显示的高度
*/

/**
* en: z-index
* zh-CN: z-index
*/

/**
* icon
*/

/**
* calendar
*/

/**
* en: forward and backward arrows color
* zh-CN: 前进后退的箭头颜色
*/

/**
* en: text color of week highlight
* zh-CN: 周末高亮的文本颜色
*/

/**
* en: background color when selected
* zh-CN: 选中时的背景颜色
*/

/**
* en: text color when disabled
* zh-CN: 禁用时的文本颜色
*/

/**
* en: text color of today
* zh-CN: 今天的文本颜色
*/

/**
* en: font size of cell
* zh-CN: 单元格的字号
*/

/**
* en: background color
* zh-CN: 背景颜色
*/

/**
* en: size of date cell
* zh-CN: 日期单元格尺寸大小
*/

/**
* en: line height of date cell
* zh-CN: 日期单元格的行高
*/

/**
* en: text color of header
* zh-CN: 头部的文本颜色
*/

/**
* week-calendar
*/

/**
* search
*/

/**
* en: text color of cancel button
* zh-CN: 取消按钮文本颜色
*/

/**
* en: background color
* zh-CN: 背景颜色
*/

/**
* en: text color of placeholder
* zh-CN: placeholder文本颜色
*/

/**
* radio
*/

/**
* en: checked icon color
* zh-CN: 选中状态的图标颜色
*/

/**
* loadmore
*/

/**
* en: not used
* zh-CN: 未被使用
*/

/**
* loading
*/

/**
* en: z-index
* zh-CN: z-index
*/
.vux-tab-ink-bar {
  position: absolute;
  height: 0.026667rem;
  bottom: 0;
  left: 0;
  background-color: #04BE02;
  text-align: center;
}
.vux-tab-ink-bar-transition-forward {
  -webkit-transition: right 0.3s cubic-bezier(0.35, 0, 0.25, 1), left 0.3s cubic-bezier(0.35, 0, 0.25, 1) 0.09s;
  transition: right 0.3s cubic-bezier(0.35, 0, 0.25, 1), left 0.3s cubic-bezier(0.35, 0, 0.25, 1) 0.09s;
}
.vux-tab-ink-bar-transition-backward {
  -webkit-transition: right 0.3s cubic-bezier(0.35, 0, 0.25, 1) 0.09s, left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: right 0.3s cubic-bezier(0.35, 0, 0.25, 1) 0.09s, left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.vux-tab-bar-top .vux-tab-ink-bar {
  top: 0;
}
.vux-tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  height: 0.586667rem;
  position: relative;
}
.vux-tab button {
  padding: 0;
  border: 0;
  outline: 0;
  background: 0 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.vux-tab .vux-tab-item {
  display: block;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: -webkit-gradient(linear, left top, left bottom, from(#e5e5e5), color-stop(#e5e5e5), to(rgba(229, 229, 229, 0))) bottom left no-repeat;
  background: linear-gradient(180deg, #e5e5e5, #e5e5e5, rgba(229, 229, 229, 0)) bottom left no-repeat;
  background-size: 100% 0.013333rem;
  font-size: 0.186667rem;
  text-align: center;
  line-height: 0.586667rem;
  color: #666;
}
.vux-tab .vux-tab-item.vux-tab-selected {
  color: #04BE02;
  border-bottom: 0.04rem solid #04BE02;
}
.vux-tab-bar-top .vux-tab .vux-tab-item {
  background: -webkit-gradient(linear, left top, left bottom, from(#e5e5e5), color-stop(#e5e5e5), to(rgba(229, 229, 229, 0))) top left no-repeat;
  background: linear-gradient(180deg, #e5e5e5, #e5e5e5, rgba(229, 229, 229, 0)) top left no-repeat;
  background-size: 100% 0.013333rem;
}
.vux-tab-bar-top .vux-tab .vux-tab-item.vux-tab-selected {
  border-bottom: none;
  border-top: 0.04rem solid #04BE02;
}
.vux-tab .vux-tab-item.vux-tab-disabled {
  color: #ddd;
}
.vux-tab.vux-tab-no-animate .vux-tab-item.vux-tab-selected {
  background: 0 0;
}

/** when=prop:custom-bar-width **/
.vux-tab-bar-inner {
  display: block;
  background-color: #04BE02;
  margin: auto;
  height: 100%;
  -webkit-transition: width 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: width 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.vux-tab-item-badge {
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: inline-block;
  height: 0.24rem;
  min-width: 0.24rem;
  padding: 0 0.053333rem;
  border-radius: 0.4rem;
  margin: auto 0 auto 0.053333rem;
  line-height: 0.24rem;
  font-size: 0.146667rem;
  background-clip: padding-box;
  vertical-align: middle;
}
.vux-tab-wrap {
  position: relative;
  padding-top: 0.586667rem;
}
.vux-tab-container {
  height: 0.586667rem;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  position: absolute;
}
.scrollable {
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.226667rem;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
.scrollable::-webkit-scrollbar {
  display: none;
}
.scrollable .vux-tab-ink-bar {
  bottom: 0.226667rem;
  position: absolute;
}
.scrollable .vux-tab-item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 22%;
          flex: 0 0 22%;
}/**
* actionsheet
*/

/**
* en: primary type text color of menu item
* zh-CN: 菜单项primary类型的文本颜色
*/

/**
* en: warn type text color of menu item
* zh-CN: 菜单项warn类型的文本颜色
*/

/**
* en: default type text color of menu item
* zh-CN: 菜单项default类型的文本颜色
*/

/**
* en: disabled type text color of menu item
* zh-CN: 菜单项disabled类型的文本颜色
*/

/**
* datetime
*/

/**
* tabbar
*/

/**
* tab
*/

/**
* dialog
*/

/**
* en: title and content's padding-left and padding-right
* zh-CN: 标题及内容区域的 padding-left 和 padding-right
*/

/**
* x-number
*/

/**
* checkbox
*/

/**
* check-icon
*/

/**
* Cell
*/

/**
* Mask
*/

/**
* Range
*/

/**
* Tabbar
*/

/**
* Header
*/

/**
* Timeline
*/

/**
* Switch
*/

/**
* Button
*/

/**
* en: border radius
* zh-CN: 圆角边框
*/

/**
* en: font color
* zh-CN: 字体颜色
*/

/**
* en: margin-top value between previous button, not works when there is only one button
* zh-CN: 与相邻按钮的 margin-top 间隙，只有一个按钮时不生效
*/

/**
* en: button height
* zh-CN: 按钮高度
*/

/**
* en: the font color in disabled
* zh-CN: disabled状态下的字体颜色
*/

/**
* en: the font color in disabled
* zh-CN: disabled状态下的字体颜色
*/

/**
* en: font size
* zh-CN: 字体大小
*/

/**
* en: the font size of the mini type
* zh-CN: mini类型的字体大小
*/

/**
* en: the line height of the mini type
* zh-CN: mini类型的行高
*/

/**
* en: the background color of the warn type
* zh-CN: warn类型的背景颜色
*/

/**
* en: the background color of the warn type in active
* zh-CN: active状态下，warn类型的背景颜色
*/

/**
* en: the background color of the warn type in disabled
* zh-CN: disabled状态下，warn类型的背景颜色
*/

/**
* en: the background color of the default type
* zh-CN: default类型的背景颜色
*/

/**
* en: the font color of the default type
* zh-CN: default类型的字体颜色
*/

/**
* en: the background color of the default type in active
* zh-CN: active状态下，default类型的背景颜色
*/

/**
* en: the font color of the default type in disabled
* zh-CN: disabled状态下，default类型的字体颜色
*/

/**
* en: the background color of the default type in disabled
* zh-CN: disabled状态下，default类型的背景颜色
*/

/**
* en: the font color of the default type in active
* zh-CN: active状态下，default类型的字体颜色
*/

/**
* en: the background color of the primary type
* zh-CN: primary类型的背景颜色
*/

/**
* en: the background color of the primary type in active
* zh-CN: active状态下，primary类型的背景颜色
*/

/**
* en: the background color of the primary type in disabled
* zh-CN: disabled状态下，primary类型的背景颜色
*/

/**
* en: the font color of the plain primary type
* zh-CN: plain的primary类型的字体颜色
*/

/**
* en: the border color of the plain primary type
* zh-CN: plain的primary类型的边框颜色
*/

/**
* en: the font color of the plain primary type in active
* zh-CN: active状态下，plain的primary类型的字体颜色
*/

/**
* en: the border color of the plain primary type in active
* zh-CN: active状态下，plain的primary类型的边框颜色
*/

/**
* en: the font color of the plain default type
* zh-CN: plain的default类型的字体颜色
*/

/**
* en: the border color of the plain default type
* zh-CN: plain的default类型的边框颜色
*/

/**
* en: the font color of the plain default type in active
* zh-CN: active状态下，plain的default类型的字体颜色
*/

/**
* en: the border color of the plain default type in active
* zh-CN: active状态下，plain的default类型的边框颜色
*/

/**
* en: the font color of the plain warn type
* zh-CN: plain的warn类型的字体颜色
*/

/**
* en: the border color of the plain warn type
* zh-CN: plain的warn类型的边框颜色
*/

/**
* en: the font color of the plain warn type in active
* zh-CN: active状态下，plain的warn类型的字体颜色
*/

/**
* en: the border color of the plain warn type in active
* zh-CN: active状态下，plain的warn类型的边框颜色
*/

/**
* swipeout
*/

/**
* Cell
*/

/**
* Badge
*/

/**
* en: badge background color
* zh-CN: badge的背景颜色
*/

/**
* Popover
*/

/**
* Button tab
*/

/**
* en: not used
* zh-CN: 未被使用
*/

/**
* en: border radius color
* zh-CN: 圆角边框的半径
*/

/**
* en: border color
* zh-CN: 边框的颜色
*/

/**
* en: not used
* zh-CN: 默认状态下圆角边框的颜色
*/

/**
* en: not used
* zh-CN: 未被使用
*/

/**
* en: default background color
* zh-CN: 默认状态下的背景颜色
*/

/**
* en: selected background color
* zh-CN: 选中状态下的背景颜色
*/

/**
* en: not used
* zh-CN: 未被使用
*/

/* alias */

/**
* en: not used
* zh-CN: 未被使用
*/

/**
* en: default text color
* zh-CN: 默认状态下的文本颜色
*/

/**
* en: height
* zh-CN: 元素高度
*/

/**
* en: line height
* zh-CN: 元素行高
*/

/**
* Swiper
*/

/**
* checklist
*/

/**
* popup-picker
*/

/**
* popup
*/

/**
* popup-header
*/

/**
* form-preview
*/

/**
* sticky
*/

/**
* group
*/

/**
* en: margin-top of title
* zh-CN: 标题的margin-top
*/

/**
* en: margin-bottom of title
* zh-CN: 标题的margin-bottom
*/

/**
* en: margin-top of footer title
* zh-CN: 底部标题的margin-top
*/

/**
* en: margin-bottom of footer title
* zh-CN: 底部标题的margin-bottom
*/

/**
* toast
*/

/**
* en: text size of content
* zh-CN: 内容文本大小
*/

/**
* en: default top
* zh-CN: 默认状态下距离顶部的高度
*/

/**
* en: position top
* zh-CN: 顶部显示的高度
*/

/**
* en: position bottom
* zh-CN: 底部显示的高度
*/

/**
* en: z-index
* zh-CN: z-index
*/

/**
* icon
*/

/**
* calendar
*/

/**
* en: forward and backward arrows color
* zh-CN: 前进后退的箭头颜色
*/

/**
* en: text color of week highlight
* zh-CN: 周末高亮的文本颜色
*/

/**
* en: background color when selected
* zh-CN: 选中时的背景颜色
*/

/**
* en: text color when disabled
* zh-CN: 禁用时的文本颜色
*/

/**
* en: text color of today
* zh-CN: 今天的文本颜色
*/

/**
* en: font size of cell
* zh-CN: 单元格的字号
*/

/**
* en: background color
* zh-CN: 背景颜色
*/

/**
* en: size of date cell
* zh-CN: 日期单元格尺寸大小
*/

/**
* en: line height of date cell
* zh-CN: 日期单元格的行高
*/

/**
* en: text color of header
* zh-CN: 头部的文本颜色
*/

/**
* week-calendar
*/

/**
* search
*/

/**
* en: text color of cancel button
* zh-CN: 取消按钮文本颜色
*/

/**
* en: background color
* zh-CN: 背景颜色
*/

/**
* en: text color of placeholder
* zh-CN: placeholder文本颜色
*/

/**
* radio
*/

/**
* en: checked icon color
* zh-CN: 选中状态的图标颜色
*/

/**
* loadmore
*/

/**
* en: not used
* zh-CN: 未被使用
*/

/**
* loading
*/

/**
* en: z-index
* zh-CN: z-index
*/
.vux-slider {
  overflow: hidden;
  position: relative;
}
.vux-slider > .vux-indicator,
.vux-slider .vux-indicator-right {
  position: absolute;
  right: 0.2rem;
  bottom: 0.133333rem;
}
.vux-slider > .vux-indicator > a,
.vux-slider .vux-indicator-right > a {
  float: left;
  margin-left: 0.08rem;
}
.vux-slider > .vux-indicator > a > .vux-icon-dot,
.vux-slider .vux-indicator-right > a > .vux-icon-dot {
  display: inline-block;
  vertical-align: middle;
  width: 0.08rem;
  height: 0.08rem;
  border-radius: 0.04rem;
  background-color: #d0cdd1;
}
.vux-slider > .vux-indicator > a > .vux-icon-dot.active,
.vux-slider .vux-indicator-right > a > .vux-icon-dot.active {
  background-color: #04BE02;
}
.vux-slider > .vux-indicator-center {
  right: 50%;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}
.vux-slider > .vux-indicator-left {
  left: 0.2rem;
  right: auto;
}
.vux-slider > .vux-swiper {
  overflow: hidden;
  position: relative;
}
.vux-slider > .vux-swiper > .vux-swiper-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.vux-slider > .vux-swiper > .vux-swiper-item > a {
  display: block;
  width: 100%;
  height: 100%;
}
.vux-slider > .vux-swiper > .vux-swiper-item > a > .vux-img {
  display: block;
  width: 100%;
  height: 100%;
  background: center center no-repeat;
  background-size: cover;
}
.vux-slider > .vux-swiper > .vux-swiper-item > a > .vux-swiper-desc {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1.4em;
  font-size: 0.213333rem;
  padding: 0.266667rem 0.666667rem 0.16rem 0.173333rem;
  margin: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.7) 100%);
  color: #fff;
  text-shadow: 0 0.013333rem 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}
.main[data-v-50b56378] {
  width: 100%;
  padding-top: 1.6rem;
  height: 100%;
  overflow: auto;
  background-color: #f6f6f6;
}
.title[data-v-50b56378] {
  text-align: center;
  padding: 0.53rem;
  position: relative;
  padding-top: 0.3rem;
}
.title span[data-v-50b56378] {
  font-size: 0.48rem;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #333333;
}
.title .iconfont[data-v-50b56378] {
  position: absolute;
  left: 0.37rem;
  color: #8e8e8e;
  top: 0.48rem;
  font-size: 0.4rem;
}
.form-wrap[data-v-50b56378] {
  padding: 0 0.56rem;
}
.form-wrap input[data-v-50b56378],
.form-wrap textarea[data-v-50b56378] {
  border: none;
  width: 100%;
  font-size: 0.37rem;
  font-weight: 400;
  color: #666666;
  padding: 0.27rem;
  display: block;
  margin-bottom: 0.35rem;
}
.form-wrap textarea[data-v-50b56378] {
  resize: none;
  height: 2.51rem;
}
.form-wrap input[data-v-50b56378]::-webkit-input-placeholder,
.form-wrap textarea[data-v-50b56378]::-webkit-input-placeholder {
  color: #b1b1b1;
}
.form-wrap input[data-v-50b56378]::-moz-placeholder,
.form-wrap textarea[data-v-50b56378]::-moz-placeholder {
  color: #b1b1b1;
}
.form-wrap input[data-v-50b56378]:-ms-input-placeholder,
.form-wrap textarea[data-v-50b56378]:-ms-input-placeholder {
  color: #b1b1b1;
}
.form-wrap input[data-v-50b56378]::-ms-input-placeholder,
.form-wrap textarea[data-v-50b56378]::-ms-input-placeholder {
  color: #b1b1b1;
}
.form-wrap input[data-v-50b56378]::placeholder,
.form-wrap textarea[data-v-50b56378]::placeholder {
  color: #b1b1b1;
}
.form-wrap .select-address-wrap[data-v-50b56378] {
  margin-bottom: 0.35rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
}
.form-wrap .select-address-wrap .address-left[data-v-50b56378] {
  width: 1.3rem;
  height: 2.13rem;
  font-size: 0.37rem;
  font-weight: 400;
  color: #b1b1b1;
  line-height: 2.13rem;
  padding-left: 0.23rem;
  border-right: 0.013333rem solid #dcdcdc;
  padding-right: 0.093333rem;
}
.form-wrap .select-address-wrap .address-right[data-v-50b56378] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.form-wrap .select-address-wrap .address-right input[data-v-50b56378] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border: none;
  font-size: 0.37rem;
  font-weight: 400;
  color: #666666;
  padding: 0.27rem;
  width: 100%;
  margin-bottom: 0;
}
.form-wrap .select-address-wrap .address-right input[data-v-50b56378]::-webkit-input-placeholder,
.form-wrap .select-address-wrap .address-right textarea[data-v-50b56378]::-webkit-input-placeholder {
  color: #b1b1b1;
}
.form-wrap .select-address-wrap .address-right input[data-v-50b56378]::-moz-placeholder,
.form-wrap .select-address-wrap .address-right textarea[data-v-50b56378]::-moz-placeholder {
  color: #b1b1b1;
}
.form-wrap .select-address-wrap .address-right input[data-v-50b56378]:-ms-input-placeholder,
.form-wrap .select-address-wrap .address-right textarea[data-v-50b56378]:-ms-input-placeholder {
  color: #b1b1b1;
}
.form-wrap .select-address-wrap .address-right input[data-v-50b56378]::-ms-input-placeholder,
.form-wrap .select-address-wrap .address-right textarea[data-v-50b56378]::-ms-input-placeholder {
  color: #b1b1b1;
}
.form-wrap .select-address-wrap .address-right input[data-v-50b56378]::placeholder,
.form-wrap .select-address-wrap .address-right textarea[data-v-50b56378]::placeholder {
  color: #b1b1b1;
}
.form-wrap .code-wrap[data-v-50b56378] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.35rem;
}
.form-wrap .code-wrap input[data-v-50b56378] {
  border: none;
  height: 1.07rem;
  width: 5.81rem;
  font-size: 0.37rem;
  font-weight: 400;
  color: #666666;
  padding: 0.27rem;
}
.form-wrap .code-wrap input[data-v-50b56378]::-webkit-input-placeholder {
  color: #b1b1b1;
}
.form-wrap .code-wrap input[data-v-50b56378]::-moz-placeholder {
  color: #b1b1b1;
}
.form-wrap .code-wrap input[data-v-50b56378]:-ms-input-placeholder {
  color: #b1b1b1;
}
.form-wrap .code-wrap input[data-v-50b56378]::-ms-input-placeholder {
  color: #b1b1b1;
}
.form-wrap .code-wrap input[data-v-50b56378]::placeholder {
  color: #b1b1b1;
}
.form-wrap .code-wrap .authcode-wrap[data-v-50b56378] {
  width: 2.84rem;
  height: 1.07rem;
}
.form-wrap .code-wrap .authcode-wrap img[data-v-50b56378] {
  width: 100%;
  height: 100%;
}
.form-wrap .confirm[data-v-50b56378] {
  display: inline-block;
  width: 5.44rem;
  height: 1.47rem;
  background-image: url("/static/confirm.png");
  background-size: 100%;
  font-size: 0.4rem;
  font-weight: 400;
  color: #ffffff;
  padding-top: 0.4rem;
  margin-bottom: 0.45rem;
}
.error-tip[data-v-50b56378] {
  color: #eb5555;
  font-size: 0.3rem;
  margin: 0.133333rem;
  text-align: left;
}